import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import Meta from '@components/Meta';
import {ResponsiveContainer, FontScale} from '@components/layout';
import HeroSectionStandalone from '@components/Hero';
import {
  SubNavSection,
  FullWidthImageSection,
  OneImageWithContentSection,
  TwoImagesWithContentSection,
  CrosslinkSection,
} from '@components/sections';
import {StrategicPartnerHeroCallout} from '@components/SectionCallout';
import {detailPagesData} from '@components/Subnavigation/detailPagesData';
import {usePageContext} from '@components/Page';
import {ThemeProvider, Colors, styled} from '@styles';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';

const StyledCrosslinkSection = styled(CrosslinkSection).withConfig({
  componentId: 'strategicPartnersCrosslink'
})`
  background: ${Colors.FogLight};
`;

export default function StrategicPartnerDetail({data}): JSX.Element {
  usePageContext({shouldInvertMenuColors: true});
  const {cms} = data;
  const {hero, body, searchDescription, seoTitle, parentPage} = cms.page;
  const {header: heroHeader, image: heroImage} = getDataFromBlockTypes(hero);
  const {crossLink, detail, benefit, blog} = getDataFromBlockTypes(body);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Transparent,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '600px',
              bg: Colors.Transparent,
              fg: Colors.Charcoal,
              ctaIconFg: Colors.Blue,
              ctaIconBg: Colors.Blue,
            }}
          >
            <HeroSectionStandalone heroSectionType={'short'} altHero>
              <StrategicPartnerHeroCallout {...heroHeader} />
            </HeroSectionStandalone>
          </ThemeProvider>
          <SubNavSection
            filter={false}
            categories={detailPagesData}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />
          {heroImage && heroImage.image && (
            <FullWidthImageSection {...heroImage} />
          )}
          <ThemeProvider
            theme={{
              bg: Colors.Blue,
              fg: Colors.Charcoal,
              ctaFg: Colors.Blue,
              ctaBg: Colors.White,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <OneImageWithContentSection {...detail} />
          </ThemeProvider>
          <TwoImagesWithContentSection offset={false} {...benefit} />
          <ThemeProvider
            theme={{
              bg: Colors.Blue,
              fg: Colors.Charcoal,
              ctaFg: Colors.Blue,
              ctaBg: Colors.White,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <OneImageWithContentSection {...blog} />
          </ThemeProvider>
          <StyledCrosslinkSection {...crossLink} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment SPDetailPageFragment on CMS_SPDetailPage {
    seoTitle
    searchDescription
    parentPage {
      title
      url
    }
    hero {
      __typename
      ...headerIconBlockFragment
      ...heroCmsImageFragment
    }
    body {
      __typename
      ... on CMS_SectionBodyImageExternalBlock {
        blockType
        headerbodyBlock {
          headline
          body
        }
        linkBlock {
          blockType
          link
          caption
        }
        imageBlock {
          caption
          image {
            ...cmsFluidImageFragment
          }
        }
      }
      ...sectionBodyTwoImagesFragment
      ...sectionBodyImageFragment
      ...crosslinkBlockFragment
    }
  }
  query StrategicPartnerDetailQuery($id: ID!) {
    cms {
      page(id: $id) {
        ...SPDetailPageFragment
      }
    }
  }
`;
