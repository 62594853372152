import React from 'react';

import {Paragraph} from '@components/typography';
import {styled} from '@styles';

interface Props {
  header: {
    caption: string;
    file: {
      url: string;
    };
  };
  subheader: string;
}

const Image = styled.div.withConfig({
  componentId: 'strategicPartnerHeroCalloutImage'
})`
  width: 65%;
  max-width: 100%;
  margin: 3.5em 0 2em;
  background-image: ${({src}) => (src ? `url(${src})` : null)};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
  height: 150px;
`;

const Container = styled.div.withConfig({
  componentId: 'strategicParnterHeroCalloutContainer'
})`
  display: flex;
  flex-direction: column;
`;

export default function StrategicPartnerHeroCallout({
  header,
  subheader,
}: Props): JSX.Element {
  return (
    <Container>
      {header && header.file && header.file.url && (
        <Image
          src={header.file.url}
          aria-label={header && header.caption ? header.caption : ''}
        />
      )}
      {subheader && <Paragraph>{subheader}</Paragraph>}
    </Container>
  );
}
